import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css'
// import tabbing from './libs/tabbing'


const home = {};

home.sliderHome = function(){
  $('.slider-home').slick({
    arrows: false,
    dots: false,
    slidesToShow: 1
  })
}

home.sliderLiving = function(){
  const total = $('.slider-living').children().length;
  $('.slider-living').slick({
    arrows: false,
    dots: false,
    slidesToShow: 1,
    infinite: false,
    fade: true,
    asNavFor: '.asnav-living',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows:true,
        }
      }
    ]
  })
  if (total > 2){
    $('.asnav-living').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-living',
      infinite: false,
      dots: false,
      arrows: false,
      variableWidth: true,
      focusOnSelect: true,
      responsive:[
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            infinite: true,
          }
        }
      ]
    });
  }
  else {
    $('.asnav-living').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-living',
      infinite: false,
      dots: false,
      arrows: false,
      variableWidth: true,
      focusOnSelect: true,
      responsive:[
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          }
        }
      ]
    });
  }
}


home.init = function(){
  // this.sliderHome()
  this.sliderLiving()
}

$(document).ready(() => {
	// const element = $(".wrap-tab")
	// tabbing($).init(element)	
});

home.init()